import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { env } from '$env/dynamic/public';

setTimeout(() => {
	Sentry.init({
		dsn: 'https://9c9b1832e84c47c79b2f3ce4c2328fa3@o4506042062602240.ingest.sentry.io/4506042065682432',
		tracesSampleRate: 0.25,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 0.1,

		// If you don't want to use Session Replay, just remove the line below:
		integrations: [
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false
			})
		],
		enabled: ['QA', 'BETA', 'PROD'].includes(env.PUBLIC_ENV)
	});
}, 5000);

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
